<template>
  <div>
    <div ref="navBarDiv">
      <ReportWindowNavBar/>
    </div>
    <div :style="reportViewerStyle">
      <ReportViewer ref="reportViewer" :showCloseButton="false" :inReportWindow="true"/>
    </div>
    <div :style="reportEditorStyle">
      <ReportEditor ref="reportEditor" :closeOpensViewer="true" :inReportWindow="true"/>
    </div>
  </div>
</template>

<script>
import ReportViewer from './ReportViewer.vue'
import ReportWindowNavBar from './ReportWindowNavBar.vue'
import broadcast from '../common/broadcast'
import webServices from '../common/webServices'
import workflow from '../common/workflow'

export default {
  name: 'reportWindow',
  components: {
    ReportEditor: () => import('./ReportEditor.vue'),
    ReportViewer,
    ReportWindowNavBar
  },
  data() {
    return {
    };
  },
  created() {
    // Check if UID provided for this window.
    //
    let hrefParts = window.location.href.split('?')
    this.$log.debug("ReportWindow: "+hrefParts[0])
    if (hrefParts.length > 1) {
      let params = hrefParts[1].split('&')
      if (params.length > 0) {
        for(var i = 0; i < params.length; i++) {
          let paramParts = params[i].split('=')
          if (paramParts.length == 2) {
            this.$log.debug(`Handling param=[${paramParts[0]}] value=[${paramParts[1]}]`)
            switch (paramParts[0].toLowerCase()) {
              case 'uid': {
                let uid = decodeURIComponent(paramParts[1])
                this.$store.state.uid = uid
                break
              }
              default:
                break
            }
          }
        }
      }
    }
    
    webServices.stopWorklistRefresh()
    broadcast.channel.onmessage = (event) => {
      this.handleMessage(event)
    }
    window.onbeforeunload = (event) => {
      this.handleWindowBeforeUnload(event)
    }
    window.onresize = (event) => {
      this.handleWindowResize(event)
    }
    window.onunload = (event) => {
      this.handleWindowUnload(event)
    }
  },
  mounted() {
    broadcast.postMessage(broadcast.REPORT_WINDOW_READY_MSG, this.$store.state.uid)
  },
  computed: {
    reportEditorStyle() {
      return (this.$store.state.activeComponent == 'ReportEditor') ? "display: block;" : "display: none;"
    },
    reportViewerStyle() {
      return (this.$store.state.activeComponent == 'ReportViewer') ? "display: block;" : "display: none;"
    },
    activeComponent() {
      return this.$store.state.activeComponent
    }
  },
  watch: {
    activeComponent(newVal/*, oldVal*/) {
      if (newVal == 'ReportViewer') {
        this.$refs.reportViewer.show()
        broadcast.postMessage(broadcast.REPORT_WINDOW_VIEWING_MSG, this.$store.state.uid)
      }
      else if (newVal == 'ReportEditor') {
        this.$refs.reportEditor.show()
        broadcast.postMessage(broadcast.REPORT_WINDOW_EDITING_MSG, this.$store.state.uid)
      }
    }
  },
  methods: {
    handleMessage(event) {
      try {
        let message = event.data
        if (message !== undefined) {
          if ((message['type'] == broadcast.SELECTED_STUDY_MSG) && (message['payload']['target'] == workflow.TARGET_REPORT_WINDOW) &&
            (message['payload']['windowUid'] == this.$store.state.uid)) {
            this.$log.debug(broadcast.SELECTED_STUDY_MSG+' message received')
            if (this.$store.state._selectedStudyUid != message['payload']['study_uid']) {
              if (this.$store.state._selectedStudyUid != '') {
                let payload = {
                  study_uid: this.$store.state._selectedStudyUid,
                  target: workflow.TARGET_REPORT_WINDOW,
                  window_uid: this.$store.state.uid
                }
                broadcast.postMessage(broadcast.CLOSE_STUDY_MSG, payload)
              }

              this.$store.commit('changeWorklist', [ message['payload'] ])
              this.$store.commit('changeReportId', message['payload']['reportId'])
              this.$store.commit("changeSelectedStudyUids", message['payload']['study_uid'])
              this.$store.commit('changeActiveComponent', 'ReportViewer')
              this.$store.commit('changeActiveStudyUid', '')
              this.$store.commit('changeActiveStudyUid', message['payload']['study_uid'])
              this.$refs.reportViewer.show()
            }
          }
        }
      }
      catch(error) {
        this.$log.error("Error handling message: "+error.message)
      }
    },
    handleWindowBeforeUnload(event) {
      if (this.$store.getters.isReportOpenForEdit(this.$store.state.uid)) {
        // Browser may present its own message.
        event.preventDefault();
        return event.returnValue = 'Report open in editor may have unsaved changes. Are you sure you want to close this window?'
      }
    },
    handleWindowResize(/*event*/) {
      broadcast.postMessage(broadcast.REPORT_WINDOW_RESIZE_MSG, this.$store.state.uid)
    },
    handleWindowUnload(/*event*/) {
      broadcast.postMessage(broadcast.REPORT_WINDOW_CLOSED_MSG, {
        study_uid: this.$store.state._selectedStudyUid,
        window_uid: this.$store.state.uid
      })
    }
  }
};
</script>
<style scoped>
</style>